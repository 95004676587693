<!--文档-->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('帮助文档')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :xs="24" :sm="12">
       <h3>{{msg('视频教程')}}</h3>  
      <el-table :data="videos" style="width: 100%" :height="docHeight" size="mini" ref="dataTable" stripe>
        <el-table-column prop="name" :label="msg('名称')" :fixed="!isMinScreen"></el-table-column>
        <el-table-column prop="url" :label="msg('操作')" min-width="80" width="100">
          <template slot-scope="scope">
            <el-link @click="viewVideo(scope.row)">查看<i class="el-icon-view el-icon--right"></i> </el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :xs="24" :sm="12">
      <h3>{{msg('文档教程')}}</h3>
      <el-table :data="docs" style="width: 100%" :height="docHeight" size="mini" ref="dataTable" stripe>
        <el-table-column prop="name" :label="msg('名称')" :fixed="!isMinScreen"></el-table-column>
        <el-table-column prop="url" :label="msg('操作')" min-width="80" width="100">
          <template slot-scope="scope">
            <el-link @click="viewDoc(scope.row)">查看<i class="el-icon-view el-icon--right"></i> </el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>

  <el-dialog :title="msg('播放教程')" :visible.sync="visiable" :fullscreen="isMinScreen"  
      width="940px" :close-on-click-modal="false" :before-close="beforeClose">
    <video :src="videoUrl" controls="controls" ref="videoRef" 
      height="460" autoplay width="900"></video>
  </el-dialog> 

</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'Document',
  components: {},
  mounted:function(){},
  data(){
    return{
        visiable: false,
        videoUrl: null,
        videos: [ //视频教程列表
          {name: '大陆直接贴单发货教程', url: this.$kit.api.docUrl+"video/"+encodeURIComponent('大陆直接贴单发货教程.mp4')},
          {name: '订单资料导入', url: this.$kit.api.docUrl+"video/"+encodeURIComponent('订单资料导入.mp4')},
          {name: '分包打单功能介绍', url: this.$kit.api.docUrl+"video/"+encodeURIComponent('分包打单功能介绍.mp4')},
          {name: '国内快递绑定介绍', url: this.$kit.api.docUrl+"video/"+encodeURIComponent('国内快递绑定介绍.mp4')},
          {name: '台湾贴单发货教程', url: this.$kit.api.docUrl+"video/"+encodeURIComponent('台湾贴单发货教程.mp4')},
          {name: '退件转寄教程', url: this.$kit.api.docUrl+"video/"+encodeURIComponent('退件转寄教程.mp4')},
          {name: '系统筛选功能介绍', url: this.$kit.api.docUrl+"video/"+encodeURIComponent('系统筛选功能介绍.mp4')},
        ], 
        docs: [ //文档教程列表
          {name: '操作手册', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('操作手册.docx')},
          {name: '资料导入', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('0、资料导入.docx')},
          {name: '大陆直贴面单', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('1、大陆直贴面单.docx')},
          {name: '台湾贴单', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('2、台湾贴单.docx')},
          {name: '分包打单功能介绍', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('3、分包打单功能介绍.docx')},
          {name: '系统筛选功能介绍', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('4、系统筛选功能介绍.docx')},
          {name: '退件转寄', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('5、退件转寄.docx')},
          {name: '国内快递绑定介绍', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('6、国内快递绑定介绍.docx')},
          {name: '现货仓储操作指引', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('现货仓储操作指引.docx')},
          {name: '绑定店铺后的订单整理技巧', url: this.$kit.api.docUrl+"doc/"+encodeURIComponent('绑定店铺后的订单整理技巧.docx')},
        ],  
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 250;
        }
    }
  },
  methods: {
    viewVideo(item){
      this.visiable = true;
      this.videoUrl = item.url;
    },
    viewDoc(item){
      window.location.href = item.url;
    },
    beforeClose(done){
      this.$nextTick(() => {
        this.$refs.videoRef.pause();
      });
      done();
    }
  }
}
</script>

<style class="scss" scoped>


</style>
